import { useState } from "react";
import Draggable from "react-draggable";
import { useNavigate } from 'react-router-dom';
import logo from "../assets/images/logo-header.png";
import { url_game } from "./config";

export default function GameFull() {
    const navigate = useNavigate();
    const [isDrag, setIsDrag] = useState(false);

    const handleDrag = (e, data) => {
        setIsDrag(true)
    };

    const handleStop = (e, data) => {
        if (isDrag) {
            setIsDrag(false)
        } else {
            navigate(-1)
        }
    };

    return (
        <div className="w-screen h-screen flex items-center justify-center relative">
            <Draggable
                axis="y"
                defaultPosition={{ x: 0, y: 0 }}
                onDrag={handleDrag}
                onStop={handleStop}
                handle=".handle"
            >
                <div className="absolute top-11 left-0 w-full z-50">
                    <div
                        className="handle absolute bg-white text-white border-white border-[1px] py-1 px-2 rounded shadow-lg z-50 cursor-pointer flex items-center justify-center"
                        style={{ left: "-10px" }}
                    >
                        <div>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="#05D369"
                                className="w-6 h-6 mr-1"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M15 19l-7-7 7-7"
                                />
                            </svg>
                        </div>
                        <img className="w-8 h-8 rounded-full" src={logo} alt="logo" />
                    </div>
                </div>
            </Draggable>
            <iframe
                id="game-iframe"
                src={url_game}
                width="100%"
                height="100%"
                frameBorder="0"
                title="2048"
                style={{
                    aspectRatio: "16",
                    objectFit: "cover",
                    maxWidth: "fit-content",
                    border: "2px solid #8F7A65",
                }}
            ></iframe>
        </div>
    )
}
