import { useEffect, useState } from "react";
// import Popular from "./popular/page";
import { FaPlay } from "react-icons/fa";
import { url_game } from "./config";

function useMobilePlatform() {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    const mobilePlatforms = /android|iphone|ipod|windows phone|blackberry/i;
    const isIOS = /iPad|iPhone|iPod/.test(userAgent) ||
      (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1);

    setIsMobile(mobilePlatforms.test(userAgent) || isIOS);
  }, []);

  return isMobile;
}

export default function Home() {
  const isMobile = useMobilePlatform();

  const handleFullScreen = () => {
    const iframe = document.getElementById("game-iframe");
    if (iframe.requestFullscreen) {
      iframe.requestFullscreen();
    } else if (iframe.mozRequestFullScreen) {
      iframe.mozRequestFullScreen();
    } else if (iframe.webkitRequestFullscreen) {
      iframe.webkitRequestFullscreen();
    } else if (iframe.msRequestFullscreen) {
      iframe.msRequestFullscreen();
    }
  };

  const handleClick = () => {
    window.location.href = "#/game";
  };

  return (
    <div className="relative z-50 bg-[#FAF8EF]">
      {!isMobile ?
        <div className="2xl:flex lg:flex flex items-center justify-center 2xl:h-[85vh] lg:h-[75vh] h-[65vh] lg:mx-24 mx-8">
          <iframe
            id="game-iframe"
            src={url_game}
            width="100%"
            height="100%"
            frameBorder="0"
            title="2048"
            style={{
              aspectRatio: "16 / 9",
              objectFit: "cover",
              borderRadius: "8px",
              maxWidth: "fit-content",
              border: "2px solid #8F7A65",
            }}
          ></iframe>
        </div>
        :
        <div className="flex flex-col items-center justify-center h-[35vh] lg:mx-24 mx-8 opacity-90 rounded-lg">
          <div className="relative w-full h-full flex flex-col items-center justify-center">
            <div className="absolute inset-0 bg-playnow opacity-50"></div>
            <button className="w-[60px] h-[60px] bg-white rounded-full flex items-center justify-center relative animate-zoom" onClick={handleClick}>
              <FaPlay className="text-black w-6 h-6" />
            </button>
            <a className="poppins-bold font-bold text-[20px] text-white mt-3 relative"
              href="#/game"
              style={{ textShadow: "1px 1px 2px black, -1px -1px 2px black, 1px -1px 2px black, -1px 1px 2px black" }}
            >Play now</a>
          </div>

        </div>
      }
      <div className="flex items-center justify-center">
        <div className="container">
          {!isMobile && <div className="2xl:flex lg:flex md:hidden hidden justify-center mt-6 2xl:mb-[40px] lg:mb-[28px] mb-[28px]">
            <button
              onClick={handleFullScreen}
              className="btn-full-screen 2xl:py-6 2xl:px-16 lg:py-3 lg:px-8 md:py-3 md:px-8 px-4 py-2 rounded"
            >
              <span className="lilita-one-regular 2xl:text-4xl lg:text-3xl md:text-3xl text-white">
                Full Screen
              </span>
            </button>
          </div>}
          {/* <Popular /> */}
          <div className='2xl:px-56 2xl:mt-14 2xl:pb-14 md:pb-14 md:mt-14 mt-10 lg:px-20 md:px-9 px-6 pb-10 bg-[#F8FFFF]'>
            <h1 className='2xl:text-[40px] md:text-[40px] text-[32px] lilita-one-regular text-[#8F7A65] pt-8'>Game 2048 – Conquer the Ultimate Number Puzzle Challenge</h1>
            <div className='2xl:mt-6 md:mt-6'>
              <span className='text-base flex !text-justify md:text-lg 2xl:text-lg lg:text-lg poppins-regular text-[#383838]'>
                Game 2048 is a globally renowned tile-matching puzzle game that has captivated millions of players. With its simple yet highly addictive gameplay, the game provides not only entertainment but also an opportunity to sharpen your logical thinking and strategic planning. Your mission is straightforward: merge tiles with the same number to create the legendary 2048 tile.
              </span>
            </div>
          </div>
          <div className="text-[#383838] lg:px-20 md:px-9 px-6 2xl:pt-20 lg:pt-20 md:pt-14 content-des 2xl:px-56 2xl:pb-20 md:pb-14 pt-14 pb-14">
            <h2 className="lilita-one-regular 2xl:text-2xl lg:text-2xl text-2xl md:text-2xl">
              What is Game 2048?
            </h2>
            <br />
            <div className="2xl:text-lg lg:text-lg text-base poppins-regular flex !text-justify">
              First introduced in 2014, Game 2048 quickly became a worldwide sensation due to its minimalist design and challenging mechanics. Using only numbered tiles and a 4x4 grid, this game proves that simplicity can deliver an engaging experience.
            </div>
            <br />
            <div className="2xl:text-lg lg:text-lg text-base poppins-regular flex !text-justify">
              In the game, you slide tiles across a grid. When two tiles of the same value collide, they merge into a single tile with double the value. Your ultimate goal is to create a tile with the number 2048 while keeping the board from filling up.
            </div>
            <br />
            <h2 className="lilita-one-regular 2xl:text-2xl lg:text-2xl text-2xl md:text-2xl">
              Why You Should Play Game 2048
            </h2>
            <br />
            <h2 className="lilita-one-regular 2xl:text-xl lg:text-xl text-xl md:text-xl !font-medium">
              1. Suitable for All Ages
            </h2>
            <br />
            <div className="2xl:text-lg lg:text-lg text-base poppins-regular flex !text-justify">
              Whether you’re a student looking to exercise your brain, a busy professional seeking relaxation, or a senior wanting a fun pastime, Game 2048 is perfect for everyone.
            </div>
            <br />
            <h2 className="lilita-one-regular 2xl:text-xl lg:text-xl text-xl md:text-xl !font-medium">
              2. Fun Meets Mental Workout
            </h2>
            <br />
            <div className="2xl:text-lg lg:text-lg text-base poppins-regular flex !text-justify">
              This game is more than just fun – it stimulates your mind. Each move challenges your logic and planning skills, making it an excellent mental exercise.
            </div>
            <br />
            <h2 className="lilita-one-regular 2xl:text-xl lg:text-xl text-xl md:text-xl !font-medium">
              3. No Installation Required
            </h2>
            <br />
            <div className="2xl:text-lg lg:text-lg text-base poppins-regular flex !text-justify">
              Enjoy the convenience of playing directly in your browser without the need for downloads or installations.
            </div>
            <br />
            <h2 className="lilita-one-regular 2xl:text-xl lg:text-xl text-xl md:text-xl !font-medium">
              4. Endless Challenges
            </h2>
            <br />
            <div className="2xl:text-lg lg:text-lg text-base poppins-regular flex !text-justify">
              Even after reaching the 2048 tile, the game doesn’t stop. Keep playing to achieve higher tiles like 4096, 8192, and beyond. Test your limits and set new records!
            </div>
            <br />
            <h2 className="lilita-one-regular 2xl:text-2xl lg:text-2xl text-2xl md:text-2xl">
              How to Play Game 2048
            </h2>
            <br />
            <h2 className="lilita-one-regular 2xl:text-lg lg:text-lg text-2xl md:text-lg">
              1. Slide to Move:
            </h2>
            <br />
            <div className="2xl:text-lg lg:text-lg text-base poppins-regular flex !text-justify">
              Use the arrow keys (up, down, left, right) on your keyboard or swype on a touchscreen device to slide the tiles.
            </div>
            <br />
            <h2 className="lilita-one-regular 2xl:text-lg lg:text-lg text-2xl md:text-lg">
              2. Merge Same-Value Tiles:
            </h2>
            <br />
            <div className="2xl:text-lg lg:text-lg text-base poppins-regular flex !text-justify">
              When two tiles with the same number collide, they merge into one with double the value. For example, two tiles of 2 will merge into 4, two tiles of 4 will merge into 8, and so on.
            </div>
            <br />
            <h2 className="lilita-one-regular 2xl:text-lg lg:text-lg text-2xl md:text-lg">
              3. Reach the Goal:
            </h2>
            <br />
            <div className="2xl:text-lg lg:text-lg text-base poppins-regular flex !text-justify">
              Continue merging tiles until you create a tile with the number 2048. If the board becomes full with no valid moves, the game ends.
            </div>

            <div className="control flex items-center 2xl:mt-10 lg:mt-10 mt-4 md:mt-10">
              <div className="2xl:pl-8 2xl:py-8 lg:pl-8 lg:py-8 px-8 py-8">
                <h2 className="2xl:text-2xl lg:text-2xl md:text-2xl text-2xl font-bold text-start lilita-one-regular flex">
                  Tips to Achieve High Scores in Game 2048
                </h2>
                <ul
                  style={{
                    listStyleType: "disc",
                    marginLeft: "30px",
                  }}
                >
                  <li className="2xl:text-lg lg:text-lg md:text-lg text-lg text-start mt-4 poppins-regular">
                    Focus on a Corner: Always try to keep your largest tile in one corner, such as the bottom-left or bottom-right.
                  </li>
                  <li className="2xl:text-lg lg:text-lg md:text-lg text-lg text-start mt-4 poppins-regular">
                    Avoid Vertical Moves: Minimize up-and-down movements to prevent disrupting the tile arrangement.
                  </li>
                  <li className="2xl:text-lg lg:text-lg md:text-lg text-lg text-start mt-4 poppins-regular">
                    Control the Board: Keep smaller tiles from getting in the way of your larger ones. Clear paths whenever possible.
                  </li>
                  <li className="2xl:text-lg lg:text-lg md:text-lg text-lg text-start mt-4 poppins-regular">
                    Plan Ahead: Anticipate the outcome of your moves and think 2-3 steps ahead to avoid dead ends.
                  </li>
                </ul>
              </div>
            </div>
          </div>


        </div>
      </div>
    </div>
  );
}
